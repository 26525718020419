import React, { useState } from "react";
import logo from "../../src/assets/logo.svg";
import EnterDestination from "./EnterDestination";
import leftleaf from "../../src/assets/left-leaf-img.svg";
import rightLeaf from "../../src/assets/right-leaf-img.svg";
import FinalScreen from "./FinalScreen";
import ChooseVehicle from "./ChooseVehicle";
import TripConfirmation from "./TripConfirmation";

const leafAnimation = {
  position: "absolute",
};

const leftLeafAnimation = {
  ...leafAnimation,
  left: "-12%",
  bottom: "26%",
  animation: "moveLeftLeaf 4s infinite linear",
};

const rightLeafAnimation = {
  ...leafAnimation,
  right: "-12%",
  top: "10%",
  animation: "moveRightLeaf 5s infinite linear",
};

const termsAndPrivacyText = {
  fontFamily: "'Poppins','sans-serif'",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#000000",
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    minHeight: "100vh",
    overflow: "hidden",
    position: "relative",
  },
  logoContainer: {
    position: "absolute",
    left: "7rem", // Adjust as needed
    top: "4rem", // Adjust as needed
  },
  enterDestinationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gridColumn: "span 1",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 2rem",
  },
  leftText: {
    ...termsAndPrivacyText,
    textAlign: "left",
  },
  rightText: {
    ...termsAndPrivacyText,
    textAlign: "right",
  },
};

const keyframes = `
@keyframes moveLeftLeaf {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes moveRightLeaf {
  0% {
    transform: rotate(0deg);
  50% {
    transform: rotate(25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
`;

export default function Login() {
  const [currentStep, setCurrentStep] = useState(1);

  const renderCurrentComponent = () => {
    switch (currentStep) {
      case 1:
        return (
          <EnterDestination
            onNext={() => setCurrentStep(2)}
            onBefore={() => setCurrentStep(1)}
          />
        );
      case 2:
        return (
          <ChooseVehicle
            onNext={() => setCurrentStep(3)}
            onBefore={() => setCurrentStep(1)}
          />
        );
      case 3:
        return (
          <TripConfirmation
            onNext={() => setCurrentStep(4)}
            onBefore={() => setCurrentStep(2)}
          />
        );
      case 4:
        return <FinalScreen onBefore={() => setCurrentStep(3)} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-pattern">
      <style>{keyframes}</style>
      <div style={styles.container}>
        <div style={{ position: "relative", overflow: "hidden" }}>
          <div style={leftLeafAnimation}>
            <img src={leftleaf} alt="leftleaf" />
          </div>
          <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" width={120} height={100} />
          </div>
        </div>

        <div style={styles.enterDestinationContainer}>
          {renderCurrentComponent()}
        </div>

        <div style={{ position: "relative", overflow: "hidden" }}>
          <div style={rightLeafAnimation}>
            <img src={rightLeaf} alt="rightLeaf" />
          </div>
        </div>

        <div style={styles.footer}>
          <div style={styles.leftText}>Terms & Conditions | Privacy Policy</div>
          <div style={styles.rightText}>© 2024. Powered by driveEmode</div>
        </div>
      </div>
    </div>
  );
}
