import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./components/Login";
import FinalScreen from "./components/FinalScreen";
import EnterDestination from "./components/EnterDestination";
import TripConfirmation from "./components/TripConfirmation";

const getRoutes = () => {
  // const isLocked = false || localStorage.getItem("is_locked") === "true";
  // const isStagingEnv =
  //   API?.OHM_ENDPOINT?.toLowerCase()?.includes("staging") ||
  //   API?.ENVIRONMENT?.toLowerCase() === "staging";

  // const isLoggedIn =
  //   localStorage.getItem("session_key") && localStorage.getItem("access_token");

  let routes = [];

  // if (!isLoggedIn) {
  routes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/finalscreen",
      element: <FinalScreen />,
    },
    {
      path: "*",
      element: <Login />,
    },
    {
      path: "/enter_destination",
      element: <EnterDestination />,
    },
    {
      path: "/trip_confirmation",
      element: <TripConfirmation />,
    },
  ];
  // }
  //  else {
  //   routes = [
  //     {
  //       path: "/login",
  //       element: <Login />,
  //     },
  //     {
  //       path: "/finalscreen",
  //       element: <FinalScreen />,
  //     },
  //     {
  //       path: "*",
  //       element: <Login />,
  //     },
  //     {
  //       path: "/enter_destination",
  //       element: <EnterDestination />,
  //     },
  //     {
  //       path: "/trip_confirmation",
  //       element: <TripConfirmation />,
  //     },
  //   ];
  // }

  return routes;
};

const router = createBrowserRouter(getRoutes());

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
