import React from "react";
import thanks from "../../src/assets/s5-thanks-icon.svg";

export default function FinalScreen() {
  const thanksTextStyle = {
    fontFamily: "'Roboto Flex', sans-serif",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "46.66px",
    textAlign: "center",
    color: "#096960",
    margin: "10px",
  };
  const pleaseCollecttextStyle = {
    fontFamily: "'Roboto Flex', sans-serif",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "28px",
    color: "#000000",
    margin: "10px",
    textAlign: "center",
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <img
        src={thanks}
        alt="Thanks"
        width="128px"
        height="128px"
        className="p-5"
      />
      <span style={thanksTextStyle}>Thanks for Booking!</span>
      <span style={pleaseCollecttextStyle}>Please collect your receipt</span>
    </div>
  );
}
