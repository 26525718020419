import React from "react";
import callIcon from "../../src/assets/s4-call-icon.svg";
import userIcon from "../../src/assets/s4-username-icon.svg";
import dropOff from "../../src/assets/dropoff-icon.svg";
import carImage from "../../src/assets/vehicletype-icon.svg";
import userImage from "../../src/assets/s4-user-icon.svg";
import BackButton from "../../src/assets/back-btn.svg";

export default function TripConfirmation({ onNext, onBefore }) {
  const textStyle = {
    fontFamily: "Roboto Flex, sans-serif",
    fontSize: "40px",
    fontWeight: 600,
    textAlign: "center",
    position: "absolute",
    top: "100px",
    left: "570px",
    color: "#096960",
  };

  const instructionStyle = {
    position: "absolute",
    top: "200px", // Positioned just below "Trip Confirmation"
    left: "290px", // Slightly to the left
    color: "#096960",
    fontSize: "18px",
  };

  const tripText = {
    fontFamily: "Roboto Flex, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18.66px",
    textAlign: "left",
    color: "#096960",
  };

  const tripInfo = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "800px",
    height: "200px",
    marginTop: "10px",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const reviewButtonStyles = {
    width: "300px",
    height: "40px",
    top: "693px",
    left: "819px",
    gap: "0px",
    borderRadius: "10px 10px 10px 10px",
    opacity: "0px",
    background: "linear-gradient(112.61deg, #058A9F 3.58%, #0BAC76 96.77%)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "6px",
  };

  return (
    <div>
      <div>
        <span style={textStyle}>Trip Confirmation</span>
        <span style={instructionStyle}>
          Please enter information to get mobile receipt
        </span>
        <div className="relative w-full flex justify-center gap-4 mt-12">
          <div className="w-[380px] h-[50px] rounded-lg bg-white flex items-center shadow-md border-2 border-[#50AEB5]">
            <div className="pl-2">
              <img src={callIcon} alt="location" className="text-gray-400" />
            </div>
            <input
              type="text"
              className="pl-6 pr-3 w-full bg-transparent border-none outline-none text-gray-500"
              placeholder="Enter mobile number"
              maxLength={10}
            />
          </div>
          <div className="w-[400px] h-[50px] rounded-lg bg-white flex items-center shadow-md border-2 border-[#50AEB5]">
            <div className="pl-2">
              <img src={userIcon} alt="location" className="text-gray-400" />
            </div>
            <input
              type="text"
              className="pl-6 pr-3 w-full bg-transparent border-none outline-none text-gray-500"
              placeholder="Enter your name (optional)"
            />
          </div>
        </div>
      </div>

      <div style={tripInfo}>
        <div className="items-center">
          <img src={dropOff} alt="dropOff" width={25} height={25} />
          <span style={tripText}>Drop Off</span>
        </div>
        <div className="items-center">
          <img src={carImage} alt="vehicleType" width={62} height={62} />
          <span style={tripText}>Vehicle Type & Trip Fare</span>
        </div>
        <div className="items-center">
          <img src={userImage} alt="userInfo" width={25} height={25} />
          <span style={tripText}>User Info</span>
        </div>
      </div>
      <div className=" justify-around flex">
        <img src={BackButton} alt="Back Button" onClick={onBefore} />
        <button style={reviewButtonStyles} onClick={onNext}>
          Review & Confirm Trip
        </button>
      </div>
    </div>
  );
}
