import React, { useState } from "react";
import BackButton from "../../src/assets/back-btn.svg";
import rightArrow from "../../src/assets/right-arrow.svg";

export default function ChooseVehicle({ onNext, onBefore }) {
  const [specialCardClicked, setSpecialCardClicked] = useState(false);

  const textStyle = {
    fontFamily: "Roboto Flex, sans-serif", // Adding a fallback font
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "40px",
    textAlign: "center",
    position: "absolute", // Using absolute positioning
    top: "136px",
    left: "581px",
    gap: "0px",
    color: " #096960",
  };

  const cardStyle = {
    width: "300px",
    height: "180px",
    borderRadius: "10px",
    border: "1px solid #98CBC0",
    backgroundColor: "#F6FDF6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const cards = [
    { id: 1, content: "Card 1" },
    { id: 2, content: "Card 2" },
    { id: 3, content: "Card 3" },
    { id: 4, content: "Card 4" },
  ];

  const specialCardStyle = {
    width: "300px",
    height: "180px",
    borderRadius: "10px 10px 10px 10px",
    background: specialCardClicked
      ? "linear-gradient(112.61deg, #058A9F 3.58%, #0BAC76 96.77%)"
      : "linear-gradient(112.61deg, #058A9F 3.58%, #0BAC76 96.77%)",
    opacity: specialCardClicked ? 10 : 0.4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleCardClick = () => {
    setSpecialCardClicked(true);
  };

  return (
    <>
      <div className="">
        <span style={textStyle}>Choose your vehicle</span>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div style={{ display: "flex", gap: "20px" }}>
          {cards.slice(0, 3).map((card) => (
            <div key={card.id} style={cardStyle} onClick={handleCardClick}>
              {card.content}
            </div>
          ))}
        </div>

        <div style={{ display: "flex", gap: "20px" }}>
          <div style={cardStyle}>{cards[3].content}</div>

          <div style={specialCardStyle} onClick={onNext}>
            {/* Additional card content here */}
            Special Card
            <img src={rightArrow} alt="rightArrow" width={"25px"} />
          </div>
          <div
            style={{
              width: "300px",
              height: "180px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={BackButton} alt="Back Button" onClick={onBefore} />
          </div>
        </div>
      </div>
    </>
  );
}
